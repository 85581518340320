// Customizable Area Start
import React from "react";
import {
  createStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  IconButton,
} from "@material-ui/core";
import ChangeDueDateJourneyController, {
  Props,
} from "./ChangeDueDateJourneyController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { SuccessIcon } from "./assets";
import CloseIcon from "../../../components/src/SVGIcons/CloseIcon.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) => createStyles({});
// Customizable Area End

// Customizable Area Start
export class ChangeDueDateSuccessModal extends ChangeDueDateJourneyController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, open } = this.props;
    return (
        <Dialog
          data-test-id="successDueDateModal_new"
          className={`${classes.highlightBgColor}`}
          open={open}
          aria-labelledby="success-course-success-confirm-dialog-title"
        >
          <Box padding={"30px"} className={`${classes.bgColor}`}>
            <Box display={"flex"} justifyContent={"center"} id="successDueDateModals">
              <Box display={"flex"} justifyContent={"center"} width={"94%"} data-test-id="successDueDateModal_Img">
                <img src={SuccessIcon} alt="success icon" />
              </Box>
              <Box width={"6%"}>
                <IconButton
                  data-test-id="Succes_one"
                  aria-label="more"
                  style={{ float: "right" }}
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={this.props.onCancel}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <DialogContent style={{ padding: "0px" }} data-test-id="NotValied">
              <Box textAlign={"center"} mt={"20px"} mb={"10px"}>
                <Typography
                  className={`${classes.textPrimaryColor} ${classes.fontText24} ${classes.fontBold600}`}
                  data-test-id="Succes_two"
                >
                  Due Date Changed Successfully
                </Typography>
              </Box>
              <Box mb={"30px"} textAlign={"center"} mt={"10px"}>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
                  component={"span"}
                  data-test-id="Succes_one_for"
                >
                  Due date for
                </Typography>
                <Typography
                  data-test-id="Succes_one_energy"
                  className={`${classes.fontBold600} ${classes.textPrimaryColor}`}
                  component={"span"}
                  style={{ fontSize: "16px" }}
                >
                  {" "}
                  Wave Energy{" "}
                </Typography>
                <Typography
                  className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
                  component={"span"}
                  data-test-id="Succes_one_from"
                >
                  {" "}
                  topic from{" "}
                </Typography>
                <Typography
                  data-test-id="Succes_one_physics"
                  className={`${classes.fontBold600} ${classes.textPrimaryColor}`}
                  component={"span"}
                >
                  Physics{" "}
                </Typography>
                <Box>
                  <Typography
                    className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}
                    component={"span"}
                    data-test-id="Succes_one_Chamged"
                  >
                    {" "}
                    course has been changed to{" "}
                  </Typography>
                  <Typography
                    data-test-id="Succes_one_june"
                    className={`${classes.fontBold600} ${classes.textPrimaryColor}`}
                    component={"span"}
                  >
                    June 16, 2023{" "}
                  </Typography>
                </Box>
              </Box>
              <Box
                justifyContent={"space-between"}
                display={"flex"}
                alignItems={"center"}
                data-test-id="Succes_one_Box"
              >
                <CustomButton
                  btnStyle={successmodalBtns}
                  btnText="Okay"
                  buttonId="boxy-course-modal-cancel-btn"
                  buttonVariant={"outlined"}
                  isDisabled={false}
                  type={"button"}
                  handleButtonClick={this.props.onCancel}
                />
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const successmodalBtns = {
  padding: "5px",
  width: "100%",
  height: "50px",
  borderRadius: "10px",
  fontWeight: 600,
  fontSize: "16px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(ChangeDueDateSuccessModal);
// Customizable Area End
