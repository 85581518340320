// Customizable Area Start
import React from "react";
import { Box, Divider, Grid, Typography, IconButton,Tooltip } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
} from "@material-ui/core/styles";
import CustomTable from "../../../components/src/CustomTable.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import AssessmentAssignedController, {
  ITableProps,
  Props,
} from "./AssessmentAssignedController.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import CustomTextField from "../../../components/src/CustomTextField.web";
import SearchIcon from "../../../components/src/SVGIcons/SearchIcon.web";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CustomEmptyResultComponent from "../../../components/src/CustomEmptyResultComponent.web";
import CustomPaginationComponent from "../../../components/src/CustomPaginationComponent.web";
import  AddRemarkModal  from "./AddRemarkModal.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    dividerWidthAll: {
      height: "5px",
    },
    myGradebookMainContainer: {
      maxHeight: "100%",
      overflowX: "auto",
      borderRadius: "0px 0px 10px 10px",
      padding: "15px 20px",
    },
    schoolYearFilterDiv: {
      [theme.breakpoints.down("xs")]: {
        marginBottom: "10px",
      },
    },
    gradeMainBox: {
      display: "flex",
      width: "100% !important",
      "@media (max-width: 1280px)": {
        width: "1179px !important",
      },
    },
    CoursesTableBox: {
      display: "flex",
      padding: "15px 0px 15px 14px",
      borderRadius: "12px",
      width: "85%",
      maxHeight: "600px",
      overflow: "auto",
      [theme.breakpoints.down("xs")]: {
        width: "85% !important",
      },
    },
    courseLogoBg: {
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "2px",
    },
    courseLogo: {
      width: "27px",
      height: "27px",
      objectFit: "contain",
    },
   
    subjectItemRow: {
      display: "flex",
      flexDirection: "column",
      borderBottom: "1px solid rgba(224, 224, 224, 1)"
    },
    secondRow: {
      "& > div:nth-child(1)": {
        width: "30%",
      },
      "& > div:nth-child(3)": {
        width: "20%",
      },
      "& > div:nth-child(2)": {
        width: "20%",
      },
      "& > div:nth-child(5)": {
        width: "20%",
      },
      "& > div:nth-child(6)": {
        width: "10%",
      },
    },
    cell: {
      padding: "4px",
    },
    subjectTitleCell: {
      width: "30%",
      display: "flex",
      alignItems: "center",
    },
    subjectName: {
      display: "block",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    quarterCell: {
      width: "14%",
      display: "flex",
      alignItems: "center",
    },
    quarterCellDueDate: {
      width: "30%",
      display: "flex",
      alignItems: "center",
    },
    quarterCellassign: {
      width: "40%",
      display: "flex",
      alignItems: "center",
    },
    subjectCoursesDetailsTable: {
      overflowX: "auto",
      width: "96%",
      marginBottom: "12px",
      marginTop: "15px",
    },
    show: {
      display: "block",
      transition: "display 0.3s ease-out",
    },
    hide: {
      display: "none",
      transition: "display 0.3s ease-in",
    },
    dividerTableHeader: {
      // height: "1px",
      // background: "#F1F1F1",
      // marginTop: "15px",
      // marginBottom: "5px",
    },
    searchInputBox: {
      "&MuiSvgIcon": {
        root: {
          height: "16px",
          width: "16px",
        },
      },
    },
    textEllipsis: {
      maxWidth: "150px",
      display: "block",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    submissionValue: {
      minWidth: "60px",
      textAlign: "left",
    }, customWidth: {
      maxWidth: '150px',
      backgroundColor: '#FFE4E4',
      color: '#ED1111',
      padding: '12px 30px 12px 6px',
  },
  });
const searchTheme = createTheme({
  overrides: {
    MuiInputBase: {
      input: {
        "&::placeholder": {
          fontSize: "14px",
        },
      },
    },
    MuiSvgIcon: {
      root: {
        height: "16px",
        width: "16px",
      },
    },
    MuiFormControl: {
      root: {
        justifyContent: "center",
        height: "35px",
        display: "flex",
        marginLeft: "24px",
        alignItems: "center",
      },
    }
  },
});
const tableTheme = createTheme({
  overrides: {
    MuiTableCell: {
      body: {
        paddingTop: "0px !important",
        paddingBottom: "0px !important",
        paddingRight: "0px !important",
        paddingLeft: "0px !important",
      },
      head: {
        padding: "16px 0px",
      },
    },
  },
});

// Customizable Area End

// Customizable Area Start
export class AssessmentAssigned extends AssessmentAssignedController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }

  getHeadingLabel = (heading: ITableProps): string => {
    const { currentRole } = this.props;
    return currentRole === "parent teacher" && heading.id === "classAvg"
      ? "Student Avg"
      : heading.label;
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const StyledComponent = this.getClassNameHeadRow(GradeTableHeadingRow, wideFirstChild);
    const StyleBox = this.getClassNameRow(secondRow,wideChildRow)
    const {
      schoolSubjectsList,
      selectedSubjectId,
      assessmentStudentsColumns,
      subjectTableColumns,
      assessmentStudents,
      paginationData,
      openEditGradeAndRemarkModal,
      editGradeStudentId,
      editGradeStudentName,
      editGradeMaxScore,
      editGradeTotalMarks,
    } = this.state;
    const subjectTableColumn = this.props.searchstu
    ? subjectTableColumns.filter(
        (column) => column.id !== "submission" && column.id !== "classAvg" &&  column.id !== "moreMenu" 
      )
    : subjectTableColumns.filter(
      (column) => column.id !== "assignedon" 
    );

    console.log("ffffffffff",subjectTableColumn)
    return (
      <>
        <Box
          display={"flex"}
          flexWrap={"wrap"}
          gridGap={"25px"}
          px={3.5}
          py={3}
          className={classes.bgColor}
        >
          <Box display={"flex"} alignItems={"center"} data-test-id="selectSub">
            <Box display={"flex"} flexDirection={"column"}>
              <Typography
                data-test-id="selectSub"
                className={`${classes.textCommonColor} ${classes.fontText14}`}
                component={"span"}
                gutterBottom
              >
                {`Subject`}
              </Typography>
              <CustomSelectDropdown
                fieldId="select-dropdown-assessment-grading-component"
                fieldValue={this.state.selectedSubjectComponentDDValue}
                fieldName="selectedSubjectComponentDDValue"
                handleChange={this.handleSelectSubjectDDValue}
                renderValue={this.handleSelectSubjectComponenttDDValues}
                fieldError={false}
                fieldMenuItems={this.state.subjectComponent}
                fieldStyles={gradeAssignedDropDown}
                iconComponent={
                  <DropdownIcon
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke={"#888888"}
                    strokeWidth="1.5"
                    fill={"none"}
                  />
                }
              />
            </Box>
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            data-test-id="BoxGrd"
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              data-test-id="downClass"
            >
              <Typography
                data-test-id="dropdownClass"
                className={`${classes.textCommonColor} ${classes.fontText14}`}
                component={"span"}
                gutterBottom
              >
                {`Grading Component`}
              </Typography>
              <CustomSelectDropdown
                fieldId="select-dropdown-assessment-component"
                fieldValue={this.state.selectedClassComponentDDValue}
                fieldName="selectedClassComponentDDValue"
                handleChange={this.handleGradingDropDownValues}
                renderValue={this.renderGradingDDValues}
                fieldError={false}
                fieldMenuItems={this.state.gradingAssignedComponent}
                fieldStyles={gradeAssignedDropDown}
                iconComponent={
                  <DropdownIcon
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke={"#888888"}
                    strokeWidth="1.5"
                    fill={"none"}
                  />
                }
              />
            </Box>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"start"}
            data-test-id="studentBox"
          >
            <Typography
              className={`${classes.textCommonColor} ${classes.fontText14}`}
              component={"span"}
              gutterBottom
              data-test-id="student"
            >
              {`Search`}
            </Typography>
            <Box className="searchInputBox">
              <CustomTextField
                placeHolderText={`Type here to search`}
                fieldName="SearchStudents"
                fieldId="search-students-assigned"
                fieldValue={this.state.searchStudent}
                fieldWidth={"200px"}
                fieldType="text"
                handleChange={this.handleSearchStudent}
                textFieldStyles={{ height: "36px" }}
                startAdornmentIcon={
                  <SearchIcon
                  style={{
                    width:"14px",
                    height:"14px"
                  }}
                    viewBox="0 0 16 16"
                    stroke={"#888888"}
                    strokeWidth="0.45"
                    fill={"#888888"}
                  />
                }
              />
            </Box>
          </Box>
          <Box
            alignItems={"center"}
            pt={"24px"}
            data-test-id="searchBtn"
          >
            <CustomButton
              btnText="Search"
              btnStyle={searchBtn}
              buttonId={`my-assessment-search-mobile`}
              buttonVariant={"contained"}
              type={"button"}
              handleButtonClick={this.handleSearchAssignedAssessments}
              isDisabled={false}
            />
          </Box>
        </Box>
        <Divider className={classes.dividerWidthAll} />
        <Box className={`${classes.bgColor}`} 
          style={{
              borderRadius: "0px 0px 10px 10px",
              padding: "0px 10px",
            }}  
          >
        {schoolSubjectsList.length > 0 ?
          <Grid item xs={12} data-test-id="tableGrid">
            <Box
              className={`${classes.bgColor} ${classes.myGradebookMainContainer}`}
            >
              <Box className={`${classes.gradeMainBox}`} data-test-id="gradeBox">
                <Box width={"inherit"}>
                  <StyledComponent
                    data-test-id="mapBox"
                  >
                    {subjectTableColumn?.map((heading: ITableProps) =>{
                    
                  const headingLabel = this.getHeadingLabel(heading);
                    return(
                      
                      <Box
                        width={heading.width}
                        key={heading.columnId}
                        className={`${classes.cell}`}
                        data-test-id="mapReturn"
                      >
                        <Typography
                          component={"span"}
                          className={`${classes.fontText16} ${classes.textCommonColor}`}
                          data-test-id="Lable"
                        >
                          {headingLabel}
                        
                        </Typography>
                      </Box>
                    )})}
                  </StyledComponent>
                  <Box className={classes.dividerTableHeader} />
                  {schoolSubjectsList.map((subjectItem: any) => {
                    const tooltipMess: string | undefined = this.showError(subjectItem.id)
                 return(
                    <Box key={subjectItem.id} className={classes.subjectItemRow}>
                      <StyleBox display={"flex"}  >
                        <Box
                          className={`${classes.subjectTitleCell} ${classes.cell}`}
                        >
                          <Box
                            mr={"10px"}
                            bgcolor={subjectItem?.subject?.bgColor}
                            className={classes.courseLogoBg}
                          >
                            
                            <img
                              src={subjectItem?.subject?.icon}
                              className={classes.courseLogo}
                              alt="Subject Logo"
                            />
                          </Box>
                          <Box className={classes.subjectName}>
                            <Typography
                              className={`${classes.textPrimaryColor} ${classes.fontText18} ${classes.textEllipsis}`}
                              component="span"
                            >
                              {subjectItem?.subject?.name}
                            </Typography>
                          </Box>
                          {!this.props.searchstu && (
                          <Box
                            data-testid={`subject-course-details-expand`}
                            onClick={() =>
                              this.handleSubjectAssignedTable(subjectItem.id)
                            }
                          >
                            {this.getIcon(subjectItem.id)}
                           
                          </Box>)}
                        </Box>
                        <Box className={`${classes.quarterCell} ${classes.cell}`}>
                          <Typography
                            className={`${classes.textPrimaryColor} ${classes.fontText18}`}
                            component="span"
                          >
                            {subjectItem?.gradingComponent}
                          </Typography>
                        </Box>
                        {!this.props.searchstu && (<>
                          <Box className={`${classes.quarterCell} ${classes.cell}`}> 
                           <Typography
                            className={`${classes.textPrimaryColor} ${classes.fontText18} ${classes.submissionValue}`}
                            component="span"
                          >
                            {`${subjectItem?.submission?.value}%`}
                          </Typography> 
                           <Box
                            ml={"16px"}
                            id="student-progress-bar"
                            display={"flex"}
                            alignItems="center"
                            style={{
                              height: "30px",
                              width: "100px",
                              position: "relative",
                              background: "#D2D2D2",
                              borderRadius: "7px",
                              marginLeft:"3px"
                            }}
                          >
                            <Box
                              width={`${subjectItem?.submission?.value}%`}
                              style={{
                                position: "absolute",
                                height: "30px",
                                background: "#01C996",
                                borderTopRightRadius:this.getTopRightRed(subjectItem?.submission?.value),
                                
                                borderBottomRightRadius:this.getTopRightRed(subjectItem?.submission?.value),
                                  
                                borderTopLeftRadius: "7px",
                                borderBottomLeftRadius: "7px",
                              }}
                            ></Box>
                          </Box> 
                         </Box> 
                       <Box className={`${classes.quarterCell} ${classes.cell}`}>
                          <Typography
                            className={`${classes.textPrimaryColor} ${classes.fontText18}`}
                            component="span"
                          >
                            {subjectItem?.classAvg?.name}
                          </Typography>
                          <Box ml={"4px"} >
                                <Tooltip classes={{ tooltip: classes.customWidth }} placement="right" title={tooltipMess || ''} >
                                <img
                            style={{ marginLeft: "9px" }}
                            src={subjectItem?.classAvg?.icon}
                            alt={subjectItem?.classAvg?.icon}
                          />
                                </Tooltip>
                            </Box>
                         
                        </Box>
                        </>)}
                        {this.props.searchstu && (
                        <Box className={`${classes.quarterCellassign} ${classes.cell} ` } >
                          <Typography
                            className={`${classes.textPrimaryColor} ${classes.fontText18}`}
                            component="span"
                          >
                            {subjectItem.assignedon || 'NA' }
                          </Typography>
                        </Box> )}
                        <Box className={`${classes.quarterCellDueDate} ${classes.cell}`} >
                          <Typography
                            className={`${classes.textPrimaryColor} ${classes.fontText18}`}
                            component="span"
                          >
                            {subjectItem?.dueDate}
                          </Typography>
                        </Box>
                        <Box className={`${classes.quarterCell} ${classes.cell}`}  >
                          <Typography
                            className={`${classes.textPrimaryColor} ${classes.fontText18}`}
                            component="span"
                          >
                            {subjectItem?.daysDue}
                          </Typography>
                        </Box>
                        {!this.props.searchstu && (
                        <Box width={"5%"} className={`${classes.cell}`}>
                       
                          <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={() => { }}
                          >
                            <MoreVertIcon className={`${classes.textCommonColor}`} />
                          </IconButton>
                        </Box>)}
                      </StyleBox>
                      {assessmentStudents.length > 0 ?
                        <Box
                          className={this.getClassName(subjectItem?.id)
                          }
                        >
                          <CustomTable
                            tableHeaderColumns={assessmentStudentsColumns}
                            tableBodyData={assessmentStudents}
                            tableId={`my-gradebook-subject-courses-table-${subjectItem?.id}`}
                            showMenuIcon={subjectItem.evolutionType === "manual"}
                            showEditMenuIcon={false}
                            isHandleCellAction={(subjectItem.objectiveType !=="selection") && (subjectItem.evolutionType !== "manual")}
                            handleAction={this.openAssessmentDetailPage}
                            actionButtonConfig={[
                              {
                                label: "Edit Grade",
                                action: (data: any) => this.handleEditGradeDialog(data)
                              },

                            ]}
                          />
                        </Box>
                        :
                        <Box className={this.getclassNames(subjectItem?.id)}>
                          <CustomEmptyResultComponent message="No students found in this assessment." />
                        </Box>
                      }
                    </Box>
                  )
                }
                )}
                </Box>
              </Box>
            </Box>
          </Grid>
         
          :
          <Box style={{height: "268px"}}>
          <CustomEmptyResultComponent marginStyle="0px 0px !important" message="No record found." />
          </Box>
        }
        {schoolSubjectsList.length > 0 && (
          <CustomPaginationComponent
            paginationData={paginationData}
            handlePaginationChange={this.handlePagination}
          />
        )}
        </Box>
        {openEditGradeAndRemarkModal &&
          <AddRemarkModal
            open={openEditGradeAndRemarkModal}
            onCancel={this.handleCloseEditGradeAndRemarkModal}
            onConfirm={this.handleCloseEditGradeAndRemarkModal}
            activityId={selectedSubjectId}
            studentId={editGradeStudentId}
            data-testId="update grade"
            studentName={editGradeStudentName}
            maxScore={editGradeMaxScore}
            totalScore={editGradeTotalMarks}
            handleSubmitGrade={(data: any) => { this.handleSaveEditGrade(data) }}
          />
        }
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const searchBtn = {
  width: "120px",
  textAlign: "center",
  height: "50px",
  fontSize: "18px",
  borderRadius: "12px",
  padding: "10px 10px",
  fontWeight: 500,
  float: "right",
} as const;
const gradeAssignedDropDown = {
  width: "200px",
  borderRadius: "8px",
  height: "48px",
} as const;
const GradeTableHeadingRow = styled(Box) ({
  display: 'flex',
  alignItems: 'center',
  borderBottom: '1px solid rgba(224, 224, 224, 1)',
  padding:"7px",

  '& > div:nth-child(1)': {
    width: '30%',
  },
  '& > div:nth-child(3)': {
    width: '22%',
  },
  '& > div:nth-child(2)': {
    width: '20%',
  },
  '& > div:nth-child(5)': {
    width: '20%',
  },
  '& > div:nth-child(6)': {
    width: '10%',
  },
});
const wideFirstChild = styled(Box)({
  display: "flex",
  alignItems: "center",
  borderBottom: "#979797",
  "& > div:nth-child(1)": {
    width: "50%",
  },
  "& > div:nth-child(3)": {
    width: "30%",
  },
  "& > div:nth-child(2)": {
    width: "30%",
  },
  "& > div:nth-child(5)": {
    width: "20%",
  },
});
const secondRow = styled(Box) ({
  "& > div:nth-child(1)": {
    width: "30%",
  },
  "& > div:nth-child(3)": {
    width: "22%",
  },
  "& > div:nth-child(2)": {
    width: "20%",
  },
  "& > div:nth-child(5)": {
    width: "20%",
  },
  "& > div:nth-child(6)": {
    width: "10%",
  },
})
const wideChildRow = styled(Box) ({
  "& > div:nth-child(1)": {
    width: "50%",
  },
  "& > div:nth-child(3)": {
    width: "30%",
  },
  "& > div:nth-child(2)": {
    width: "30%",
  },
  "& > div:nth-child(5)": {
    width: "20%",
  },
})


const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(AssessmentAssigned);

// Customizable Area End
