// Customizable Area Start
import React from 'react';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Dialog, DialogContent, Box, Typography } from '@material-ui/core';
import MarkAsCompleteSuccessDialogController, {
    Props
} from "./MarkAsCompleteSuccessDialogController";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from '../../../components/src/CustomButton.web';
import { combineStyles } from '../../../components/src/CombineStyles.web';
import { SuccessIcon } from './assets';

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
    createStyles({
        dueDateSuccessDialog: {
            "& .MuiPaper-rounded": {
                borderRadius: "8px",
            }
        },
    })

// Customizable Area End

// Customizable Area Start
export class MarkAsCompleteSuccessDialog extends MarkAsCompleteSuccessDialogController {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, open, customHeading, customDescription } = this.props;
        return (
            <Dialog
                open={open}
                className={`${classes.highlightBgColor} ${classes.dueDateSuccessDialog}`}
                aria-labelledby="mark-as-complete-success-dialog"
            >
                <Box padding={"30px"} className={`${classes.bgColor}`}>
                    <Box justifyContent={"center"} id="mark-as-complete-success-dialog" display={"flex"}>
                        <Box>
                            <img src={SuccessIcon} alt="success icon" />
                        </Box>
                    </Box>
                    <DialogContent style={{ padding: "0px" }}>
                        <Box mb={"10px"} textAlign={"center"} mt={"20px"}>
                            <Typography className={`${classes.textPrimaryColor} ${classes.fontText24} ${classes.fontBold600}`}>
                                {customHeading || "Wave Energy Marked as Completed"}
                            </Typography>
                        </Box>
                        <Box textAlign={"center"} mt={"10px"} mb={"30px"}>
                            {
                                <Typography className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}>
                                    {customDescription}
                                </Typography> || 
                                <>
                                    <Typography className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}>
                                        <b>Wave Energy</b> topic from <b>Physics</b>
                                    </Typography>
                                    <Typography className={`${classes.textCommonColor} ${classes.fontText18} ${classes.fontBold500}`}>
                                        course has been marked as completed
                                    </Typography>
                                </>
                            }
                        </Box>
                        <Box alignItems={"center"} display={"flex"} justifyContent={"space-between"}>
                            <CustomButton
                                btnStyle={okayButton} btnText='Okay'
                                buttonVariant={"outlined"}
                                buttonId='quiz-view-answer-cancel-btn'
                                handleButtonClick={this.props.onCancel}
                                isDisabled={false} type={"button"}
                            />
                        </Box>
                    </DialogContent>
                </Box>
            </Dialog>
        );
        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start
const okayButton = {
    height: "50px",
    width: "100%",
    border: "1px solid #0D46BC",
    padding: "5px",
    color: "#0D46BC",
    backgroundColor: "inherit",
    fontSize: "16px",
    fontWeight: 600,
    borderRadius: "10px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(MarkAsCompleteSuccessDialog);
// Customizable Area End
