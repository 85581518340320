// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { tableColumnTypes } from "../../../components/src/CustomTable.web";
import { Alert } from "./assets";
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
import { toast } from "react-toastify";
import React, { ChangeEvent } from "react";
import { Box, InputBase, Typography } from "@material-ui/core";
import { ActionMeta,  ValueType } from "react-select";
import { TOASTER_NOTIFICATION_MSG } from "../../../components/src/CommonType.web";

// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");

export interface ITableProps {
  id: string;
  label: string;
  type: string;
  columnId: number;
  width?: string;
  bgColor?: string;
}
type RowId = number | string | null;


export interface StudentProfile {
  icon?: string;
  id: number | string;
  name: string;
}
interface Pagination {
  current_page: number;
  prev_page: number | null;
  next_page: number | null;
  per_page: number;
  total_count: number;
  total_pages: number;
}

interface Meta {
  pagination: Pagination;
}
interface ApiResponse {
  data: Activity[];
  meta: Meta;
  student: Students;
}
interface Students {
  first_name: string;
  id: number;
  last_name: string;
}



interface Subject {
  name: string;
  icon: string;
  bgColor: string;
}
interface StudentReceivedGrades {
  grade: number;
  avg: number;
  graded_on: string;
}
interface StudentActivitys {
  id: number;
  activity_id: number;
  student_id: number;
  is_completed: boolean;
  created_at: string;
  updated_at: string;
  is_graded: boolean;
  total_marks: number;
  remarks: string | null;
  time_spent: number;
  allowed_attempts: number;
  submission_date: string;
  is_reattempt_allow: boolean;
}
interface ActivityGradeAttribute {
  lesson_id: number;
  activity_type: string;
  evolution_type: string;
  activities_title: string;
  objective_type: string;
  max_score: number;
  grading_component: string;
  media_url: string | null;
  allowed_attempts: number;
  due_date: string;
  show_correct_answer: boolean;
  description: string;
  instruction: string;
  task: string;
  video_desc_placement: string;
  audio_desc_placement: string;
  duration: string;
  subject: Subject;
  correct_answer: boolean;
  submission_percenttage: number;
  student_received_grade: StudentReceivedGrades;
  assessment_status: string;
  time_spent: number;
  assigned_on: string;
  due_days: number;
  last_activity_perform_at: string | null;
  student_activity: StudentActivitys;
  media_file_url: string | null;
  xapi_file_url: string | null;
  is_completed: boolean;
}

interface Activity {
  id: string;
  type: string;
  attributes: ActivityGradeAttribute;
}
interface GradeSubject {
  name: string;
  icon: string;
  bgColor: string;
}
interface UserDatas {
  id: number;
  email: string;
  role: string;
  first_name: string;
  last_name: string;
  profile_pic: string | null;
  time_spent: number | null;
  date_of_submission: string | null;
  average: number;
}
interface GradedAssessment {
  id: string;
  gradeSubject: GradeSubject;
  subjectId: number;
  gradeComponent: string;
  grade: number;
  gradeDueDate: string;
  gradeon: string;
  maxScore: number;
  average: number;
  students: UserDatas[] ; 
}
// Customizable Area End
interface Option {
  value: string | number ;  
  label: string;
}

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  classId: string[]|string;
  searchByStudent: string;
  studentIds:number| string;
  searchstu:boolean;
  getStudentData:GradedAssessment[];
  currentRole:string;
  handleInputStudentChange: (selectedOption: ValueType<Option, false>, actionMeta: ActionMeta<Option>) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedClassComponentDDValue: any;
  selectedStatusComponentDDValue: any;
  selectedSubjectComponentDDValue: any;
  gradedAssessmentComponent: Array<any>;
  searchStudent: string;
  gradeSubjectList: any;
  selectedGradeSubId: any;
  selectedSubCoursesGradesDetails: any;
  gradeSubjectTableColumns: Array<ITableProps>;
  GradeSubjCoursesDetailsTableColumns: Array<ITableProps>;
  openGradeAndRemarkModal: boolean;
  paginationData: any;
  timer: any;
  editGradeStudentId: number;
  editGradeStudentName: string;
  editGradeTotalMarks: number;
  editGradeMaxScore: number;
  menuAnchorEl: HTMLElement | null;
  selectedRowId: RowId;
 studentUpdateId:number| string
  isEditingGrade:RowId;
  currentGrade:string|number
  tempGrade: string; 
  serachGradeStudent:string
  updatedGradedData:GradedAssessment[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class AssessmentGradedController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetProfileData: string = "";
  apiGetGradedAssessments: string = "";
  apiSaveEditGrade: string = "";
  apiGetGradeStudents: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.AccoutLoginSuccess),
    ];

    this.state = {
      searchStudent: "",
      studentUpdateId:this.props.studentIds,
      updatedGradedData:this.props.getStudentData,
      serachGradeStudent:this.props.searchByStudent,
      gradedAssessmentComponent: [
        {
          id: 1,
          label: "Quiz",
          value: "quiz",
        },
        {
          id: 2,
          label: "Exam",
          value: "exam",
        },
        {
          id: 3,
          label: "Assignment",
          value: "assignment",
        },
        {
          id: 4,
          label: "Project",
          value: "project",
        }
      ],
      selectedClassComponentDDValue: "",
      selectedStatusComponentDDValue: "",
      selectedSubjectComponentDDValue: "",
      selectedGradeSubId: 1,
      selectedSubCoursesGradesDetails: [],
      gradeSubjectList: [],
      gradeSubjectTableColumns: [
        {
          id: "gradeSubject",
          columnId: 1,
          label: "Assessment Title",
          type: tableColumnTypes.SUBJECT_ICON_NAME,
          width: "50%",
        },
        {
          id: "gradeComponent",
          columnId: 2,
          label: "Grading Component",
          type: tableColumnTypes.TEXT,
          width: "14%",
        },
        {
          id: "grade on",
          columnId: 4,
          label: "Grade on",
          type: tableColumnTypes.TEXT,
          width: "14%",
        },
        {
          id: "point",
          columnId: 4,
          label: "Point",
          type: tableColumnTypes.TEXT,
          width: "14%",
        },
        {
          id: "grade",
          columnId: 4,
          label: "Grade",
          type: tableColumnTypes.TEXT,
          width: "14%",
        },
        {
          id: "gradeClassAvg",
          columnId: 4,
          label: "Average",
          type: tableColumnTypes.ASSIGNED_ICON,
          width: "14%",
        },
        {
          id: "action",
          columnId: 4,
          label: "",
          type: tableColumnTypes.ASSIGNED_ICON,
          width: "14%",
        },
      ],
      GradeSubjCoursesDetailsTableColumns: [
        {
          id: "gradeTitle",
          columnId: 1,
          label: "Name",
          type: tableColumnTypes.STUDENT_ICON,
        },
        {
          id: "gradeDateSubmitted",
          columnId: 2,
          label: "Date Submitted",
          type: tableColumnTypes.NUMBER,
        },
        {
          id: "gradeTimeEllapsed",
          columnId: 3,
          label: "Time Ellapsed",
          type: tableColumnTypes.NUMBER_LESS_WIDTH,
        },
        {
          id: "gradeAverage",
          columnId: 4,
          label: "Average",
          type: tableColumnTypes.ASSIGNED_ICON,
        },
      ],
      openGradeAndRemarkModal: false,
      paginationData: {},
      timer: null,
      editGradeStudentId: 0,
      editGradeStudentName: '',
      editGradeTotalMarks: 0,
      editGradeMaxScore: 0,
      menuAnchorEl: null,
      selectedRowId: "",     
      isEditingGrade:null,
      currentGrade:'',
      tempGrade:"",

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleAPICall()
    // Customizable Area End
  }
  // Customizable Area Start

  handleAPICall=()=>{
    if(this.props.searchByStudent.length > 0 && this.props.searchByStudent !== "All"){

      this.handleGetGradeAllStudentApi(this.props.studentIds,{})
    }else{
      this.handleGetGradedAssessmentsApi({}, {});
    }
  }
  handleMenuOpen = (event: React.MouseEvent<HTMLElement>, openId: RowId) => {
    this.setState({
      menuAnchorEl: event.currentTarget,
      selectedRowId: openId,
    });
  };

  handleMenuClose = () => {
    this.setState({
      menuAnchorEl: null,
      selectedRowId:null
    });
  };

  handleEditGrade = (idEdit:  RowId) => { 
 
    this.setState({
      isEditingGrade: idEdit,     
    });
  
  this.handleMenuClose();
};

handleGradeChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, selectedId: number|string) => {
  const newGrade = Number(event.target.value);
  const subjectToUpdate = this.state.gradeSubjectList.find((subjectItem: any) => subjectItem.id === selectedId);
  if (!subjectToUpdate) {
    return; 
  }
  const maxScore = subjectToUpdate.maxScore ; 
  if (isNaN(newGrade) || newGrade < 0 || newGrade > maxScore) {
    toast.warning(`You cannot set grade value more than max score ${maxScore}`);
    return;
  }
  this.setState(
    (prevState: S) => ({
      gradeSubjectList: prevState.gradeSubjectList.map((subjectItem: GradedAssessment) =>
        subjectItem.id === selectedId ? { ...subjectItem, grade: newGrade } : subjectItem
      ),
    }),
    () => {     
      this.handleGradeSubmit(selectedId);
    }
  );
};

handleGradeSubmit = (selectedId: number | string) => {
  const { gradeSubjectList } = this.state;
  const subjectToSubmit = gradeSubjectList && gradeSubjectList.find((subject: GradedAssessment) => subject.id === selectedId);
  const payload = {
    activity_id: Number(selectedId), 
    student_id:this.state.studentUpdateId, 
    total_marks: subjectToSubmit.grade, 
    remarks: "sending in body", 
  };  
  this.handleSaveEditGradeApi(payload);
};

  componentDidUpdate(prevProps: Readonly<Props>,prevState:S): void {
    if (!this.props.searchByStudent && this.props.classId && prevProps.classId !== this.props.classId) {
      this.handleGetGradedAssessmentsApi({}, {});
    }
    if (prevProps.searchByStudent !== this.props.searchByStudent) {
      this.handleAPICall()
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } = getMessageData(message);
      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiGetGradedAssessments) {
          this.handleGetGradedAssessmentsResponse(
            responseJson,
            errorResponse
          );
        }

        if (apiRequestCallId === this.apiSaveEditGrade) {
          this.handleSaveEditGradeResponse(
            responseJson,
            errorResponse
          );
        }
        if(apiRequestCallId === this.apiGetGradeStudents){
          this.handleGetAllGradeStudentData(
            responseJson,
            errorResponse
          );          
        }
      }
    }
  }

  handleGetAllGradeStudentData =async (responseJson: ApiResponse ,errorResponse:[]) => {    

    if (responseJson && responseJson.data) {
      let gradeassessmentListData :GradedAssessment[]= responseJson.data.map(
        (item: Activity, index: number) => {
          const activity :any = item.attributes;
          return {
            id: item.id,
            gradeSubject: {
              icon: activity.subject.icon,
              bgColor: activity.subject.color,
              name: activity.activities_title,
            },
            gradeComponent: activity.grading_component,
            grade:activity.student_received_grade.grade,
            gradeon:activity.student_received_grade.graded_on,
            subjectId: activity.subject.id,
            average:activity.student_received_grade.avg,            
            students: activity.students || [],
            gradeDueDate: activity.due_date ? moment(activity.due_date).format('MMMM DD, YYYY') : 'NA',
            subjectActivity:activity.student_activity,          
            maxScore: activity.max_score,
          };         
        }
      );
      let gradedPagination = responseJson?.meta?.pagination;     
      this.setState({
        gradeSubjectList: gradeassessmentListData,
        studentUpdateId:responseJson.student.id,
        paginationData: gradedPagination
      });
    }    
  }
  handleGradeSubjectAssignedTable = (subjectId: any) => {
    const { gradeSubjectList, selectedGradeSubId } = this.state;
    if (subjectId === selectedGradeSubId) {
      this.setState({ selectedGradeSubId: "" });
    } else {
      let studentsList: Array<any> = [];
      const selectedSubjectObj = gradeSubjectList.find(
        (subject: any) => subject?.id === subjectId
      );
      if (selectedSubjectObj?.students) {
        const studentsData: Array<any> = selectedSubjectObj.students.data;
        studentsList = studentsData.map((item: any) => {
          return {
            id: item?.id,
            gradeTitle: {
              name: `${item?.first_name} ${item?.last_name}`,
              icon: item?.profile_pic,
              id: item?.id,
            },
            gradeDateSubmitted: item.date_of_submission ? moment(item.date_of_submission).format('MMMM DD, YYYY') : 'NA',
            gradeTimeEllapsed: this.formatTimeSpent(item?.time_spent || 0),
            gradeAverage: {
              icon: "",
              name: (item?.average || 0) + '%',
            },
            totalMarks: item?.total_marks,
            maxScore: selectedSubjectObj?.maxScore,
          }
        });

      }
      this.setState({
        selectedSubCoursesGradesDetails: studentsList,
        
        selectedGradeSubId: subjectId,
      });
    }
  };

  handleSearchStudent = (event: any) => {
    this.setState({
      searchStudent: event.target?.value,
    });
  };
  handleGradedDropdownValues = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValues: any = value;
    if (fieldValues) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValues,
        };
      });
    }
  };
  renderGradedComponenttDDValues = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Option";
    }
    const { gradedAssessmentComponent } = this.state;
    const selectedGradingComponents = gradedAssessmentComponent.find(
      (grade: any) => grade?.value === selected
    );
    return selectedGradingComponents?.label;
  };
  handleEditEventDialog = (studentId: number) => {
    const { selectedSubCoursesGradesDetails } = this.state;
    const student = selectedSubCoursesGradesDetails.find((item: any) => item.id === studentId);
    const studentFullName = student?.gradeTitle?.name;
    this.setState({
      editGradeStudentId: studentId,
      editGradeStudentName: studentFullName,
      editGradeTotalMarks: student?.totalMarks,
      editGradeMaxScore: student?.maxScore,
    });
    this.handleOpenGradeAndRemarkModal();
  };

  handDeleteEventDialog = () => {
    this.handleOpenGradeAndRemarkModal();
  };

  handleGetGradedAssessmentsApi = async (searchFilter: any, pagination: any) => {
    showCustomLoader();
    const {currentRole} = this.props
    let apiConfig = currentRole==="parent teacher"? configJSON.parentTeacherAssignmentEndpoint : configJSON.teacherAssessmentEndpoint;
    let pageLimit = 10;
    if (searchFilter.studentSearch) {
      pageLimit = 100;
    }

    const baseUrl = apiConfig + `?type=graded&per_page=${pageLimit}`;
    let requestUrl = baseUrl;

    if (this.props.classId && currentRole !== "parent teacher") {
      requestUrl = requestUrl + `&class_id=[${this.props.classId}]`;
    }

    if (searchFilter.grading_component) {
      requestUrl = requestUrl + `&grading_component=${searchFilter.grading_component}`;
    }

    if (searchFilter.title) {
      requestUrl = requestUrl + `&title=${searchFilter.title}`;
    }

    if (pagination.page) {
      requestUrl = requestUrl + `&page=${pagination.page}`;
    }

    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetGradedAssessments = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetGradeAllStudentApi = async (studentId:string | number,pagination: any) => {      
    
    let pageLimit = 10;
    let requestUrl = configJSON.getAllSearchStudents + `?student_activity=graded_activity&per_page=${pageLimit}&student_id=${studentId}`
    if (pagination.page) {
      requestUrl = requestUrl + `&page=${pagination.page}`;
    }
  
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetGradeStudents = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
 

  handleGetGradedAssessmentsResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.data) {
      let assessmentList = responseJson.data.map(
        (item: any, index: number) => {
          const activity = item.attributes;
          return {
            id: item.id,
            gradeSubject: {
              name: activity.activities_title,
              icon: activity.subject.icon,
              bgColor: activity.subject.color,
            },
            subjectId: activity.subject.id,
            gradeComponent: activity.grading_component,
            gradeClassAvg: {
              icon: Alert,
              name: activity.student_received_grade || 0,
            },
            gradeDueDate: activity?.due_date ? moment(activity?.due_date).format('MMMM DD, YYYY') : 'NA',
            students: activity.students || [],
            maxScore: activity?.max_score,
          };
        });
      let gradedPagination = responseJson?.meta?.pagination;
      let updatedGradedAssessmentList = assessmentList;
      const { searchByStudent } = this.props;
      if (searchByStudent.length > 0) {
        updatedGradedAssessmentList = this.state.updatedGradedData;
      } else {
        const searchText = searchByStudent.toLocaleLowerCase();
        updatedGradedAssessmentList = assessmentList.filter((gradedAssessment: any) => {
          if (gradedAssessment.students.data && gradedAssessment.students.data.length > 0) {
            const filteredStudents = gradedAssessment.students.data.filter((student: any) => {
              const studentName = (student.first_name + ' ' + student.last_name).toLocaleLowerCase();
              return studentName.search(searchText) !== -1;
            });
            return filteredStudents.length > 0;
          }
          return false;
        });
      }  
      if (gradedPagination) {
        gradedPagination.total_count = updatedGradedAssessmentList.length;
      }
      this.setState({ gradeSubjectList: updatedGradedAssessmentList, paginationData: gradedPagination }, () => {
        if (this.state.selectedGradeSubId) {
          const studentId = this.state.selectedGradeSubId;
          this.handleGradeSubjectAssignedTable(studentId);
          setTimeout(() => {
            this.handleGradeSubjectAssignedTable(studentId);
          }, 800);
        }
      });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  handleSearchGradedAssessment = () => {
    const filters = this.handleGetFiltersObject();
    this.handleGetGradedAssessmentsApi(filters, {});
  }

  handleGetFiltersObject = () => {
    const {
      searchStudent,
      selectedSubjectComponentDDValue,
      selectedClassComponentDDValue,
    } = this.state;

    const filters = {
      subject_id: selectedSubjectComponentDDValue,
      title: searchStudent,
      grading_component: selectedClassComponentDDValue,
    }
    return filters;
  }

  handleOpenGradeAndRemarkModal = () => {
    this.setState({ openGradeAndRemarkModal: true });
  }

  handleCloseGradeAndRemarkModal = () => {
    this.setState({ openGradeAndRemarkModal: false });
  }

  handlePagination = (event: any, page: number) => {
    const filters = this.handleGetFiltersObject();
    if(this.props.searchByStudent.length > 0 && this.props.searchByStudent !== "All"){
      this.handleGetGradeAllStudentApi(this.props.studentIds,{ page: page })
    }else{
      this.handleGetGradedAssessmentsApi(filters, { page: page });
    }
  }

  handleSearchAssessmentByStudent = (student: string) => {
    const { timer } = this.state;
    if (student.length >= 1) {
      clearTimeout(timer)
      const newTimer = setTimeout(() => {
        this.handleGetGradedAssessmentsApi({ studentSearch: student }, {});
      }, 500)
      this.setState({ timer: newTimer });
    } else {
      this.handleGetGradedAssessmentsApi({}, {});
    }
  }

  formatTimeSpent = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    const pad = (num: any) => String(num).padStart(2, '0');
    return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
  }

  handleSaveEditGrade = (payload: any) => {
    this.handleCloseGradeAndRemarkModal();
    this.handleSaveEditGradeApi(payload);
  }

  handleSaveEditGradeApi = async (requestBody: any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestUrl = configJSON.updateStudentTotalMark;
    // const requestUrl = baseUrl + `?activity_id=${requestBody.activityId}&student_id=${requestBody.studentId}&total_marks=${requestBody.grade}&remarks=${requestBody.remark}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSaveEditGrade = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSaveEditGradeResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
     if(this.props.searchByStudent.length > 0){
      this.handleGetGradeAllStudentApi(this.state.studentUpdateId,{})
     }
     else{
      toast.success(TOASTER_NOTIFICATION_MSG.GRADE_UPDATE_SUCCESS);
      this.handleGetGradedAssessmentsApi({}, {});
     }       
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  resetEditGradeData = () => {
    this.setState({
      editGradeStudentId: 0,
      editGradeStudentName: '',
      editGradeTotalMarks: 0,
      editGradeMaxScore: 0,
    }, () => {
      this.handleCloseGradeAndRemarkModal();
    });
  }

  resetFilterDropdowns = () => {
    this.setState({
      selectedClassComponentDDValue: "",
      selectedStatusComponentDDValue: "",
      selectedSubjectComponentDDValue: ""
    }, () => {
      this.handleGetGradedAssessmentsApi({}, {});
    });
  }
  getGradeClasses =(subjectItem: number | string) => {
    const classes = this.props.classes
    if (subjectItem === this.state.selectedGradeSubId) {
      return `${classes.show}`}
    else {
      return `${classes.hide}`
    }
   
  }
  getGradeClassName = (subjectItem: number | string
  ) => {
    const classes = this.props.classes
    if (subjectItem === this.state.selectedGradeSubId) {
      return `${classes.show} ${classes.subjectCoursesDetailTable} ${classes.gradeCoursesTableBox} ${classes.secondaryBgColor}`
    }
    else {
      return `${classes.hide} ${classes.subjectCoursesDetailTable} ${classes.gradeCoursesTableBox} ${classes.secondaryBgColor}`
    }
  }
  getGradefilterColumn = () => {
    if (!this.props.searchstu) {
      return this.state.gradeSubjectTableColumns.filter(
        (column) => column.id !== "point" && column.id !== "grade" && column.id !=='action' 
      )
    }
    else {
      return this.state.gradeSubjectTableColumns
    }
  }
  isGradeEdit =(subjectItem:GradedAssessment) =>{
    const classes =  this.props.classes
    if(this.state.isEditingGrade === subjectItem.id ){
     return  <Box className={`${classes.gradeQuarterCell} ${classes.cell}`}    style={{width:'40%'}}>
     <InputBase
       value={subjectItem.grade || 0 }
       onChange={(event) => this.handleGradeChange(event, subjectItem.id)}                                  
       autoFocus
       className={`${classes.textPrimaryColor} ${classes.fontText18}`}
       style={{
         border: '1px solid #888888',
         width: '70px',
         height: "50px",
         borderRadius: "3px",
         textAlign: 'center', 
         display: 'flex',      
         alignItems: 'center', 
         justifyContent: 'center', 
       }}
       inputProps={{
         'aria-label': 'edit grade',
         style: { textAlign: 'center' }, 
       }}
     />
   </Box> 

    }
    else{
      return   <Box className={`${classes.gradeQuarterCell} ${classes.cell}`} >
      <Typography
        className={`${this.props.classes.textPrimaryColor} ${classes.fontText18}`}
        component="span"
      >
        {subjectItem.grade}
      </Typography>
    </Box>
    }
  }
  maxScores = (maxScore:number)=>{
    return maxScore || 'NA'
  }

  studentCellSearch = (data:StudentProfile) => {
    
    if (this.props.handleInputStudentChange) {
      const option: Option = { value: data.id, label: data.name };
      const actionMeta: ActionMeta<Option> = { action: 'select-option' };
     
      this.props.handleInputStudentChange(option ,actionMeta);
    }
  };
  // Customizable Area End
}
// Customizable Area End
